<template>
  <h1>NestOneTwo</h1>
</template>

<script>
export default {
name: "NestOneTwo"
}
</script>

<style scoped>

</style>